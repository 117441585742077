import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AcmeProductService } from 'src/app/features/acme/service/acme-product.service';
import { environment } from 'src/environments/environment';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { runInThisContext } from 'vm';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { AddWBAScreen } from '../../../error-message/addprospect-screen';
import { FormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubmissionTemplateComponent } from '../submissionTemplate/submissionTemplate.component';
import { DatacacheService } from '../../../services/datacache.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-account-executive',
  templateUrl: './account-executive.component.html',
  styleUrls: ['./account-executive.component.css']
})
export class AccountExecutiveComponent implements OnInit {
  @Input() public tabName: any;
  @Input() public selectedValue: any;
  @Input() public sdrsmTab: any;
  @Input() public wbaView: any;
  @Input() isAssesmentParamsHasMandatoryValues: any;
  @Input() AcordPermissionResponse: any
  @Output() nextTab: any = new EventEmitter<string>();
  @Output() acordStatus: any = new EventEmitter<string>();
  @Output() setCreatedAcordId: any = new EventEmitter<string>();
  @Output() setUpdatedProgressId: any = new EventEmitter<string>();
  @Output() handleAEIconStatus: any = new EventEmitter<any>();
  public _navigationPath: NavigationPath = new NavigationPath();

  account: any = [
    { label: 'Request AE Approval', value: 'Request AE Approval' },
    // { label: 'Incomplete Information', value: 'Incomplete Information' },
    // { label: 'Incomplete Contact', value: 'Incomplete Contact' },
  ]

  acc_manager: any = [
    { label: 'Approved', value: 'Approved' },
    { label: 'Declined', value: 'Declined' },
  ]

  wba_profitCenter: any = [
    { label: 'Lamb', value: 'Lamb' },
    { label: 'CREIS', value: 'CREIS' },
    { label: 'TruePartners', value: 'TruePartners' },
    { label: 'TruePartners - Wheels', value: 'TruePartners - Wheels' },
  ];

  wba_marketingStatus: any = [
    { label: 'Remarketing by AM', value: 'Remarketing by AM' },
    { label: 'Remarket by Marketing team', value: 'Remarket by Marketing team' }
  ];

  hideReviewDropdown: boolean = true;
  acordWBA: boolean = false;
  selectedReview: any;
  AcordResponse: Array<any> = [];
  requestStatus: any;
  userDetails: any;
  AcordArray: any;
  sdRsmStatus: any;
  rsmCreatedByUserName: any;
  rsmArray: any;
  loggedInUserName: any;
  wbaResponse: any;
  _warningBox: ConfirmBox;
  wbaProgressChange: any;
  loggedInUserId: any;
  addWbaScreen: AddWBAScreen;
  rsmResponse: any;
  activeWbaId: any;
  selectedRsmApproval: any;
  prospectId: any;
  wbaTransType: any;
  wbaDetails: any;
  isDirty: boolean = false;
  selectedReviewResponse: any;
  epicUniquePolicyId: any;
  showwbaprofitCenter: boolean = true;
  SelectedvalueforProfitCenter: any;
  selectedComment: any;
  reasonResponse: any;
  selectedDescription: any;
  amDropdown: boolean = false;
  marketingStatusDropdown: boolean = false;
  selectedMarketingStatus: any;
  marketingStatusResponse: any;
  selectedAeApprovalValue: string = 'Request AE Approval';
  wbaAeHistoryResponse: any;
  errorList: any[] = [];
  roleYes: boolean = false;
  isAERequestSubmit: boolean = false;
  isallowRequest: boolean = false;
  disabledSubmit: boolean = false;
  isAERequestforRequestApproval: boolean = false;
  isReviewRespReceived: boolean = false;
  isRemarketingRequestSubmit: boolean = false;

  constructor(
    private wbaService: WhiteBoardActivityService,
    private _AcmeProductService: AcmeProductService,
    private _router: Router,
    private _loaderService: LoaderService,
    private _notifierService: WBANotifierService,
    private _addWBAService: WhiteBoardActivityService,
    private formbuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private activeRouter: ActivatedRoute,
    private _dataCacheService: DatacacheService
  ) {
    this._warningBox = new ConfirmBox()
    this.addWbaScreen = new AddWBAScreen();
  }

  ngOnInit(): void {

    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    // line ID
    // setTimeout(() => {
    //   this.getWhiteBoardDetails(this.activeWbaId);
    // }, 500);
    let userDetails = JSON.parse(localStorage.getItem('userDetails')!);
    this.loggedInUserName = userDetails.user.display_name;
    this.loggedInUserId = userDetails.user.userId;
    // this.getAccessReviewList();
    // this.getAccordReasonList();

    // this.getWbaAeHistory();

    // sdrsm dropdown
    this.intializeData();
    window.scroll(0, 0);
    // wba dropdown
  }


  intializeData() {
    this.wbaDetails = this._dataCacheService.retrieveData('wba_wbaDetailsInfo')
    this.AcordResponse = this._dataCacheService.retrieveData('wba_AcordResponse');
    this.wbaResponse = this._dataCacheService.retrieveData('wba_wbaResponse');
    this.reasonResponse = this._dataCacheService.retrieveData('reasonResponseAe');
    if (!this.wbaDetails || !this.AcordResponse || !this.reasonResponse) this.getAllMaster();
    else this.mapAccntExecutive();
  }

  getAllMaster() {
    let requestBody = {
      wbaId: this.activeWbaId,
      isRenewal: true
    };
    let dataAe = {
      acordReasonType: "AE"
    }
    // var businessLineList = this.wbaService.getBusinessLineList();
    var accessReviewList = this.wbaService.getAccessReviewList(requestBody);
    var wbaActivityList = this._addWBAService.getWBAById(this.activeWbaId);
    var AeAccordReasonList = this.wbaService.getAccordReasonList(dataAe);
    forkJoin([accessReviewList, wbaActivityList, AeAccordReasonList
    ]).subscribe((resp) => {
      if (resp) {
        // this.businessLineIdResp = resp[0].businessLineList;
        this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', resp[0]);
        this.AcordResponse = resp[0].acordResponse;
        this.wbaResponse = resp[0].wbaReviews;
        this.marketingStatusResponse = resp[0]?.reMarketingStatus;
        this.wbaDetails = resp[1].whiteBoardActivityInfo;
        this.reasonResponse = resp[2].acordReasonList;
        this.mapAccntExecutive();
        this.setDataInCache();
      }
    })
  }

  setDataInCache() {
    this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
    // this._dataCacheService.compressAndCacheData('wba_businessLineIdResp', this.businessLineIdResp);
    this._dataCacheService.compressAndCacheData('wba_AcordResponse', this.AcordResponse);
    this._dataCacheService.compressAndCacheData('wba_wbaResponse', this.wbaResponse);
    this._dataCacheService.compressAndCacheData('reasonResponseAe', this.reasonResponse);
  }

  mapAccntExecutive() {
    this.mapAccesReviewList();
    this.mapwbaActivityList();
  }
  mapAccesReviewList() {
    this.isReviewRespReceived = true
    // this.AcordResponse = res?.acordResponse;
    // this.wbaResponse = res?.wbaReviews;
    let mrktStsRsp = this._dataCacheService.retrieveData('wba_AcordResponse_reviewList');
    if (mrktStsRsp) this.marketingStatusResponse = mrktStsRsp?.reMarketingStatus;
    if (mrktStsRsp?.reMarketingStatus == "Remarketing by AM" || mrktStsRsp?.reMarketingStatus == "Remarket by Marketing team") {
      this.handleAEIconStatus.emit({ label: 'AE Status', value: false });
    } else {
      this.handleAEIconStatus.emit({ label: 'AE Status', value: true });
    }
    this.AcordArray = this.AcordResponse?.find(a => a?.requestType == "AM Request Status")
    this.acordStatus = this.AcordArray?.requestStatus
    if (this.AcordResponse?.find(a => a?.requestType == "AM Approval Status")) {
      this.rsmArray = this.AcordResponse?.find(a => a?.requestType == "AM Approval Status")
      this.sdRsmStatus = this.rsmArray?.requestStatus
      this.rsmCreatedByUserName = this.rsmArray?.createdByUserName;
    }
    this.setReviewFieldsVisible();
    this.setAmFieldsVisible();
    this.setMarketingStatusFieldsVisible();
    this.acordWBA = true;
  }

  mapwbaActivityList() {
    this.checkAccessPermissionByWbaId();
    this.epicUniquePolicyId = this.wbaDetails?.epicUniquePolicyId;
    this.wbaTransType = this.wbaDetails?.transType;
    this.wbaProgressChange = this.wbaDetails.progressName;
    this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
    this.prospectId = this.wbaDetails.prospectId;
    this.setUpdatedProgressId.emit(this.wbaDetails.progressId);
    this.SelectedvalueforProfitCenter = this.wbaDetails?.profitCenter;
  }

  setReview(item: any) {
    this.isDirty = true;
    this.selectedReview = item;
  }

  setAMApproval(item: any) {
    this.isDirty = true;
    this.selectedRsmApproval = item;
  }

  setComments(item: any) {
    this.isDirty = true;
    this.selectedComment = item;
  }

  setDespcription(event: any) {
    this.isDirty = true;
    this.selectedDescription = event.target.value;
  }

  setWbaMarketingStatus(item: any) {
    this.isDirty = true;
    this.selectedMarketingStatus = item;
  }

  createAccordReview() {
    // if (!this.selectedReview) {
    //   this.showWarningBox3();
    // } else if (this.selectedReview == 'Request AE Approval' && !this.wbaDetails.contactId) {
    //   this.showWarningBox6();
    // }
    if (!this.wbaDetails.contactId) {
      this.showWarningBox6();
    }
    else {
      let data = {
        wbaId: this.activeWbaId,
        aeRequest: "Yes",
        producerRequest: "No",
        acordStatus: this.selectedAeApprovalValue,
        userId: this.loggedInUserId
      };
      this.wbaService.createAccountExecutiveReview(data).subscribe(res => {
        this.isDirty = false;
        this.selectedReviewResponse = res;
        this.setCreatedAcordId.emit(res);
        if (res) {
          this.getAccessReviewList();
        }
        this.getWhiteBoardDetails(this.activeWbaId);
        // if (res?.id === 0) {
        //   this.showWarningBox4();
        // }
      }, (err: any) => {
        if (err.status == 400) {
          let obj = err.error.errors;
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
          this.errorList = [];
          let errorMessage = arr[0].value[0]
          if (errorMessage == 'You are not authorised for this action.') {
            errorMessage = 'Access denied, your permissions do not allow you to proceed.'
          }
          this.errorList.push(errorMessage);
          this.showWarningBox7(errorMessage);
        }
        if (err.status == 500) {
          let errorMessage = "Error : " + err.message + ", Status: " + err.status;;
          this.errorList.push(errorMessage);
        }
      });
    }
  }

  showWarningBox1() {
    let message = `Please select Marketing Status`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox2() {
    let message = `Please select AM Approval`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox3() {
    let message = `Please select review status`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox4() {
    let message = this.selectedReviewResponse?.reason
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox5() {
    let message = this.rsmResponse?.reason
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox6() {
    let message = "Please select contact"
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox7(message: any) {
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox8(response: any) {
    let message = response?.reason
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }

  warningBoxCancel() {
    this._warningBox.isVisible = false;
  }

  getAccessReviewList() {
    let requestBody = {
      wbaId: this.activeWbaId,
      isRenewal: true
    };

    this.wbaService.getAccessReviewList(requestBody).subscribe(res => {
      this.isReviewRespReceived = true
      this.AcordResponse = res?.acordResponse;
      this.wbaResponse = res?.wbaReviews;
      this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', res);
      this._dataCacheService.compressAndCacheData('wba_AcordResponse', this.AcordResponse);
      this._dataCacheService.compressAndCacheData('wba_wbaResponse', this.wbaResponse);
      this.marketingStatusResponse = res?.reMarketingStatus;
      if (res?.reMarketingStatus == "Remarketing by AM" || res?.reMarketingStatus == "Remarket by Marketing team") {
        this.handleAEIconStatus.emit({ label: 'AE Status', value: false });
      } else {
        this.handleAEIconStatus.emit({ label: 'AE Status', value: true });
      }
      this.AcordArray = this.AcordResponse?.find(a => a?.requestType == "AM Request Status")
      this.acordStatus = this.AcordArray?.requestStatus
      if (this.AcordResponse?.find(a => a?.requestType == "AM Approval Status")) {
        this.rsmArray = this.AcordResponse?.find(a => a?.requestType == "AM Approval Status")
        this.sdRsmStatus = this.rsmArray?.requestStatus
        this.rsmCreatedByUserName = this.rsmArray?.createdByUserName;
      }
      this.setReviewFieldsVisible();
      this.setAmFieldsVisible();
      this.setMarketingStatusFieldsVisible();
    });
    this.acordWBA = true;
  }

  setReviewFieldsVisible() {
    this.hideReviewDropdown = this.acordStatus == 'Request AE Approval' && (this.sdRsmStatus == 'AM Requested' || this.sdRsmStatus == 'AE Approved') ? false : true;
  }

  setAmFieldsVisible() {
    this.amDropdown = this.sdRsmStatus == 'AM Requested' ? true : false;
  }

  setMarketingStatusFieldsVisible() {
    this.marketingStatusDropdown = this.sdRsmStatus == 'AE Approved' && !this.marketingStatusResponse ? true : false;
  }

  updateAccessReview() {
    if (!this.selectedRsmApproval) {
      this.showWarningBox2();
    } else {
      let requestBody = {
        id: this.selectedReviewResponse?.id,
        wbaId: this.activeWbaId,
        acordStatus: this.acordStatus,
        marketingStatus: null,
        marketingUser: null,
        marketingUserId: null,
        marketingComments: "",
        rsmStatus: "",
        rsmUser: "",
        rsmUserId: "",
        rsmComments: "",
        aeUserId: this.loggedInUserId,
        aeUser: this.loggedInUserName,
        aeStatus: this.selectedRsmApproval,
        aeComments: this.selectedRsmApproval == 'Declined' ? this.selectedComment : '',
        amUserId: null,
        amUser: null,
        amStatus: null,
        amComments: '',
        profitCenter: this.SelectedvalueforProfitCenter,
        description: this.selectedRsmApproval == 'Declined' ? this.selectedDescription : ''
      }
      this.wbaService.updateAccountManagerReview(requestBody).subscribe(res => {
        this.isDirty = false;
        this.getAccessReviewList();
        this.getWhiteBoardDetails(this.activeWbaId);
        // this.getWbaAeHistory();
        this.rsmResponse = res;
        // if (res?.id === 0) this.showWarningBox5();
        this.selectedRsmApproval = '';
      }, (err: any) => {
        this.errorList = []
        if (err.status == 403) {
          // return
        }
        if (err.status == 400) {
          let obj = err.error.errors;
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
          this.errorList = [];
          let errorMessage = arr[0].value[0]
          if (errorMessage == 'You are not authorised for this action.') {
            errorMessage = 'Access denied, your permissions do not allow you to proceed.'
          }
          this.errorList.push(errorMessage);
          this.showWarningBox7(errorMessage);
        }
        if (err.status == 500) {
          let errorMessage = "Error : " + err.message + ", Status: " + err.status;;
          this.errorList.push(errorMessage);
        }
      });
    }
  }

  getWhiteBoardDetails(wbaId: any) {
    this._addWBAService.getWBAById(wbaId).subscribe(resp => {
      this.wbaDetails = resp.whiteBoardActivityInfo;
      this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
      this.checkAccessPermissionByWbaId();
      this.epicUniquePolicyId = resp.whiteBoardActivityInfo?.epicUniquePolicyId;
      this.wbaTransType = resp.whiteBoardActivityInfo?.transType;
      this.wbaProgressChange = resp.whiteBoardActivityInfo.progressName;
      this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
      this.prospectId = resp.whiteBoardActivityInfo.prospectId;
      this.setUpdatedProgressId.emit(resp.whiteBoardActivityInfo.progressId);
      this.SelectedvalueforProfitCenter = resp.whiteBoardActivityInfo?.profitCenter;
    });
  }

  openeEmailTemplateModal() {
    const modalRef = this.modalService.open(SubmissionTemplateComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });

    let data = {
      wbaId: this.activeWbaId,
      prospectId: this.prospectId,
      epicUniquePolicyId: this.epicUniquePolicyId,
      wbaTransType: this.wbaTransType,
      priorPolicyId: this.wbaDetails.priorPolicyId
    }

    modalRef.componentInstance.wbaIdData = data
  }

  // API for COMMENTS
  getAccordReasonList() {
    let data = {
      acordReasonType: "AE"
    }
    this.wbaService.getAccordReasonList(data).subscribe(res => {
      this.reasonResponse = res.acordReasonList;
      this._dataCacheService.compressAndCacheData('reasonResponseAe', this.reasonResponse);

    })
  }

  createMarketingStatus() {
    if (!this.selectedMarketingStatus) {
      this.showWarningBox1();
    } else {
      let data = {
        wbaId: this.activeWbaId,
        reMarketingStatus: this.selectedMarketingStatus
      }
      this.wbaService.createMarketingStatus(data).subscribe(res => {
        this.isDirty = false;
        if (res) {
          if (res?.reason) this.showWarningBox8(res);
          this.getAccessReviewList();
          this.getWhiteBoardDetails(this.activeWbaId);
        }
      });
    }
  }

  getWbaAeHistory() {
    let requestBody = {
      wbaId: this.activeWbaId
    }
    this.wbaService.getWbaAeHistory(requestBody).subscribe(res => {
      this.wbaAeHistoryResponse = res;
    })
  }

  saveWBA(action: string) {
    if (action === 'next') {
      this.sdRsmStatus == "Pending" ? this.nextTab.emit(this.sdrsmTab) : this.nextTab.emit(this.tabName)
    }
    else this._router.navigate([this._navigationPath.getProspectsWhiteboardsUrl()]);
  }

  checkAccessPermissionByWbaId() {
    this.AcordPermissionResponse = this.AcordPermissionResponse;
    let userDetails = JSON.parse(localStorage.getItem('userDetails')!);
    var isRoleIdAvailable: any[] = [];
    for (let index = 0; index < this.AcordPermissionResponse?.length; index++) {
      let allRolePerms = this.AcordPermissionResponse[index];
      let actionAERequest = allRolePerms.actionName

      let findRoleName = userDetails.user.roles.find((y: any) => y.id === allRolePerms.approverRoleId);
      // if(findRoleName)
      // this.disabledSubmit = true
      if (actionAERequest == 'AccountExecutiveRequest' && (allRolePerms.approverUserId == userDetails?.user?.userId || findRoleName)) {
        console.log("AAM");
        this.isAERequestSubmit = true;
      }
      if (actionAERequest == 'ReMarketingStatusRequest' && findRoleName && userDetails?.user?.userId == this.wbaDetails?.ownerUserId) {
        console.log("AAM");
        this.isRemarketingRequestSubmit = true;
      }
      if (actionAERequest == 'AccountManagerRequest') {
        let findRoleNameAE = userDetails.user.roles.find((y: any) => y.id === allRolePerms.approverRoleId);
        if (allRolePerms.approverUserId == userDetails?.user?.userId) {
          this.isAERequestforRequestApproval = true;
          this.isRemarketingRequestSubmit = true;
          console.log("AE1");
        }
        else if (findRoleNameAE?.roleName == 'Client Service Level 3' && userDetails?.user?.userId == this.wbaDetails?.ownerUserId) {
          this.isAERequestforRequestApproval = true;
          this.isRemarketingRequestSubmit = true;
          console.log("AE2");
        }
        else if (findRoleNameAE?.roleName != 'Client Service Level 3' && findRoleNameAE) {
          this.isAERequestforRequestApproval = true;
          this.isRemarketingRequestSubmit = true;
          console.log("AE3");
        }
      }
      const findRolePerms = userDetails.user.roles.find((loggedInUserRolePerms: any) => loggedInUserRolePerms.id === allRolePerms.approverRoleId);
      let isUserIdAvailable = userDetails?.user?.userId == allRolePerms.approverUserId;

      if (findRolePerms) {
        console.log('findRolePerms', findRolePerms)
        console.log('index', index)
        this.roleYes = false;
        //break;
      }
      if (isUserIdAvailable) {
        this.roleYes = false;
        // break;
      }
      this.roleYes = true;
    }

    //let actionAERequest = this.AcordPermissionResponse.filter((x: any) => x.actionName == 'AccountExecutiveRequest')
    //this.isAERequestSubmit = actionAERequest.some((item: any) => item.approverUserId === userDetails?.user?.userId);
  }

  closeError() {
    this.roleYes = false;
  }


}
