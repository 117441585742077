import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AcmeProductService } from 'src/app/features/acme/service/acme-product.service';
import { environment } from 'src/environments/environment';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { runInThisContext } from 'vm';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { AddWBAScreen } from '../../../error-message/addprospect-screen';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { SubmissionTemplateComponent } from '../submissionTemplate/submissionTemplate.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatacacheService } from '../../../services/datacache.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-acord',
  templateUrl: './acord.component.html',
  styleUrls: ['./acord.component.css']
})
export class AcordComponent implements OnInit {
  @Input() public tabName: any;
  @Input() public selectedValue: any;
  @Input() public sdrsmTab: any;
  @Input() public wbaView: any;
  @Input() isAssesmentParamsHasMandatoryValues: any;
  @Output() nextTab: any = new EventEmitter<string>();
  @Output() acordStatus: any = new EventEmitter<string>();
  @Output() setCreatedAcordId: any = new EventEmitter<string>();
  @Output() setUpdatedProgressId: any = new EventEmitter<string>();
  @Output() openBor: any = new EventEmitter<boolean>();
  @Output() borValue: any = new EventEmitter<string>();
  @Output() handleAcordIconStatus: any = new EventEmitter<any>();
  public _navigationPath: NavigationPath = new NavigationPath();
  activeWbaId: any;

  acords1: any = [
    { label: 'Requested', value: 'Requested' },
    { label: 'Not Needed', value: 'Not Needed' },
    { label: 'Incomplete Information', value: 'Incomplete Information' },
    { label: 'Incomplete Contact', value: 'Incomplete Contact' },
  ]
  acords2: any = [
    { label: 'Not Needed', value: 'Not Needed' },
    { label: 'Incomplete Information', value: 'Incomplete Information' },
    { label: 'Incomplete Contact', value: 'Incomplete Contact' },
  ]

  acord: any = [
    { label: 'Select', value: 'select' },
    { label: 'Completed', value: 'Completed' },
    { label: 'Incomplete', value: 'Incomplete' },
  ]

  mod: any = [
    { label: 'Attached', value: 'Attached' },
    { label: 'Not Needed', value: 'Not Needed' },
  ]

  application: any = [
    { label: 'Attached', value: 'Attached' },
    { label: 'Not Needed', value: 'Not Needed' },
  ]

  WbaReviewStatus: any = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Complete', value: 'Complete' },
    { label: 'Incomplete', value: 'Incomplete' }
  ]

  reminder: any = [
    { label: '3', value: '3' },
    { label: '5', value: '5' },
    { label: '7', value: '7' }
  ]

  bor: any = [
    { label: 'No', value: 'No' },
    { label: 'Yes', value: 'Yes' }
  ]

  hideReviewDropdown: boolean = true;
  acordWBA: boolean = false;
  SDRSMapproval: boolean = false;
  marketingReview: boolean = true;
  accessReviewList: Array<any> = [];
  selectedReview: any;
  selectedReviewResponse: any;
  AcordResponse: Array<any> = [];
  requestStatus: any;
  rolesList: Array<any> = [];
  wbaFileResponse: Array<any> = [];
  marketingReviewStatus: any;
  roleId: any;
  roleName: string = '';
  selectedAcord: any;
  wbaId: any
  userId: any;
  userDetails: any;
  roles: any;
  wbaLossFileId: number = 0;
  wbaAccordFileId: number = 0;
  wbaModDocumentFileId: number = 0;
  wbaApplicationFileId: number = 0;
  wbaReminderDropdown: boolean = true;
  assessmentWCRating: any;
  assessmentGLRating: any;
  workercompRating: any;
  glRating: any;
  assessmentName: any;
  cvgName: any;
  requestedOption: boolean = false;
  notNeededOption: boolean = false;
  userRoleId: any;
  AcordArray: any;
  marketingReviewArray: any;
  businessLineIdResp: Array<any> = [];
  BusinessLineId: any;
  marketingroleName: any;
  sdRsmStatus: any;
  BusinessLine: any;
  selectedRsmAcord: string = ''
  selectedRsmModDocument: string = ''
  selectedRsmFinancials: string = ''
  selectedRsmSOV: string = ''
  selectedRsmApplication: string = ''
  selectedRsmLossRuns: string = ''
  rsmRoleName: string = '';
  marketingCreatedByUserName: any;
  rsmCreatedByUserName: any;
  rsmArray: any;
  selectedBOR: string = '';
  loggedInUserName: any;
  wbaResponse: any;
  selectedReminderForWbaReview: any;
  epicUniquePolicyId: any;
  epicPolicyResponse: any;
  _warningBox: ConfirmBox;
  selectedWbaReviewStatus: any;
  BusinessLineName: any;
  borResponse: any;
  _isBORFormSaved: boolean = false;
  wbaProgressChange: any;
  isDirty: boolean = false;
  loggedInUserId: any;
  notBOR: any;
  addWbaScreen: AddWBAScreen;
  acordForm: any;
  submitted: boolean = false;
  sdrsmForm: any;
  sdrsmResponse: any;
  selectedRsmApproval: String = "Pending";
  wbaReviewTable: boolean = false;
  showNotNeededDropdowns: boolean = false;
  prospectId: any;
  wbaDetails: any;
  wbaTransType: any;
  selectedBORReview: any;
  borInDiscussion: boolean = false;
  submitted2: boolean = false;
  tabRefresh: any;
  takingDataFromApi: any;
  errorList: any[] = [];
  showError: boolean = false;

  constructor(
    private wbaService: WhiteBoardActivityService,
    private _AcmeProductService: AcmeProductService,
    private _router: Router,
    private _loaderService: LoaderService,
    private _notifierService: WBANotifierService,
    private _addWBAService: WhiteBoardActivityService,
    private formbuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private activeRouter: ActivatedRoute,
    private _dataCacheService: DatacacheService
  ) {
    this._warningBox = new ConfirmBox()
    this.addWbaScreen = new AddWBAScreen();
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    this._notifierService._isBORFormSaved.subscribe(resp => {
      if (resp) {
        this._isBORFormSaved = resp
        this.borResponse = this._isBORFormSaved;
      }
    });
    // line ID
    // this.getBusinessLineList();
    let userDetails = JSON.parse(localStorage.getItem('userDetails')!);
    this.loggedInUserName = userDetails?.user?.display_name;
    this.loggedInUserId = userDetails?.user?.userId;
    this.selectedReminderForWbaReview = sessionStorage.getItem('reminderDays');
    // this.getAccessReviewList();
    // sdrsm dropdown

    window.scroll(0, 0);
    // wba dropdown
    // this.getWbaBorDetailList();
    // this.getWhiteBoardDetails(this.activeWbaId);
    this.intializeData();
  }

  // Form Controls
  getFormControls() {
    this.acordForm = this.formbuilder.group({
      bor: [''],
      review: [''],
      notNeededDropdowns: this.formbuilder.array([])
    });

    this.sdrsmForm = this.formbuilder.group({
      sdrsmDropdowns: this.formbuilder.array([])
    });
    if (this.wbaDetails) this.setDefaultBorValue();
  }

  get notNeededDropdowns() {
    return this.acordForm?.controls["notNeededDropdowns"] as UntypedFormArray;
  }
  get sdrsmDropdowns() {
    return this.sdrsmForm?.controls["sdrsmDropdowns"] as UntypedFormArray;
  }

  intializeData() {
    this.tabRefresh = this._dataCacheService.retrieveData('tabRefresh');
    this.wbaDetails = this._dataCacheService.retrieveData('wba_wbaDetailsInfo');
    // this.businessLineIdResp = this._dataCacheService.retrieveData('wba_businessLineIdResp');
    this.businessLineIdResp = this._dataCacheService.retrieveData('wba_businessLine');
    this.AcordResponse = this._dataCacheService.retrieveData('wba_AcordResponse');
    this.wbaResponse = this._dataCacheService.retrieveData('wba_wbaResponse');
    this.borResponse = this._dataCacheService.retrieveData('wba_borResponse');
    this.wbaFileResponse = this._dataCacheService.retrieveData('wba_wbaFileResponse');
    // this.takingDataFromApi = this.tabRefresh.find((X: any) => X.label == "CE Status");
    if (!this.wbaDetails) this.getAllMaster();
    else this.mapAccord();
  }

  getAllMaster() {
    let requestBody = {
      wbaId: this.activeWbaId,
      isRenewal: this.wbaDetails?.transType == 'NB' ? false : true
    };
    // var businessLineList = this.wbaService.getBusinessLineList();
    var accessReviewList = this.wbaService.getAccessReviewList(requestBody);
    var wbaBorDetails = this.wbaService.getWbaBorDetailList(requestBody);
    var wbaActivityList = this._addWBAService.getWBAById(this.activeWbaId);
    forkJoin([accessReviewList, wbaBorDetails, wbaActivityList,
    ]).subscribe((resp) => {
      if (resp) {
        // this.businessLineIdResp = resp[0].businessLineList;
        this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', resp[0]);
        this.AcordResponse = resp[0].acordResponse;
        this._notifierService._wbaAccordStatus.next(resp[0].acordResponse)
        this.wbaResponse = resp[0].wbaReviews;
        this.borResponse = resp[1].wbaBorDetailList;
        this.wbaDetails = resp[2].whiteBoardActivityInfo;
        // this.tabRefresh[5].refresh = false;
        // this._dataCacheService.compressAndCacheData('tabRefresh', this.tabRefresh);
        this.mapAccord();
        this.setDataInCache();
      }
    })
  }

  setDataInCache() {
    this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
    // this._dataCacheService.compressAndCacheData('wba_businessLineIdResp', this.businessLineIdResp);
    this._dataCacheService.compressAndCacheData('wba_AcordResponse', this.AcordResponse);
    this._dataCacheService.compressAndCacheData('wba_wbaResponse', this.wbaResponse);
    this._dataCacheService.compressAndCacheData('wba_borResponse', this.borResponse);
  }

  mapAccord() {
    this.BusinessLine = this.businessLineIdResp?.find(el => el?.businessLineId == this.selectedValue)
    this.BusinessLineId = this.BusinessLine?.businessLineId;
    this.BusinessLineName = this.BusinessLine?.businessLineName;
    this.hideSelectionBox();
    this.getFormControls();
    if (this.wbaFileResponse?.length == 0) this.getWBAFileById();
    else this.mapWbaFileById()
    this.mapAccessReviewList();
    this.mapwbaActivityList();
  }
  mapwbaActivityList() {
    this.wbaProgressChange = this.wbaDetails.progressName;
    this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
    this._notifierService._isAccordRequested.next(this.wbaDetails.borInDiscussionEnable);
    this.setUpdatedProgressId.emit(this.wbaDetails.progressId);
    this.prospectId = this.wbaDetails.prospectId
    this.epicUniquePolicyId = this.wbaDetails?.epicUniquePolicyId;
    this.wbaTransType = this.wbaDetails?.transType;
    if (this.acordForm) this.setDefaultBorValue();
  }

  mapAccessReviewList() {
    this.marketingReviewArray = this.AcordResponse?.find(e => e?.requestType == 'Marketing Review');
    this.marketingReviewStatus = this.marketingReviewArray?.requestStatus
    this.marketingCreatedByUserName = this.marketingReviewArray?.createdByUserName;
    this.AcordArray = this.AcordResponse?.find(a => a?.requestType == "Acord Review Status")
    this.acordStatus = this.AcordArray?.requestStatus
    if (this.AcordResponse?.find(a => a?.requestType == "SD Approval")) {
      this.rsmArray = this.AcordResponse?.find(a => a?.requestType == "SD Approval")
      this.sdRsmStatus = this.rsmArray?.requestStatus
      this.rsmCreatedByUserName = this.rsmArray?.createdByUserName;
    }
    this.setSDrsmFields();
    this.setVisibleFields();
    this.setFieldsOnNotNeeded();
    if (this.sdRsmStatus == "Pending" || this.sdRsmStatus == "Approved") {
      this.handleAcordIconStatus.emit({ label: 'CE Status', value: false });
      this.SDRSMapproval = false;
    } else {
      this.handleAcordIconStatus.emit({ label: 'CE Status', value: true });
    }
    this.setwbaReviewTable();
    this.acordWBA = true;
    this.marketingReview = false;
  }

  hideSelectionBox() {
    if (this.BusinessLineName == 'Specialty') {
      this.notNeededOption = true;
    }
    else {
      this.requestedOption = true;
    }
  }

  getBusinessLineList() {
    this.wbaService.getBusinessLineList().subscribe(resp => {
      this.businessLineIdResp = resp.businessLineList
      this.BusinessLine = this.businessLineIdResp?.find(el => el?.businessLineId == this.selectedValue)
      this.BusinessLineId = this.BusinessLine?.businessLineId;
      this.BusinessLineName = this.BusinessLine?.businessLineName;
      this.hideSelectionBox();
      this.getWBAFileById();
      this.getFormControls();
    })
  }


  setReview(item: any) {
    this.isDirty = true
    this.selectedReview = item;
    this.showNotNeededDropdowns = item === 'Not Needed' ? true : false;
  }

  setBor(item: any) {
    this.isDirty = true
    this.selectedBORReview = item;
    this.borValue.emit(item);
    this.hideReviewDropdown = true;
    if (item == 'Yes') {
      this.acordForm.get('review').setValue('Not Needed');
      this.acordForm.get('review').disable();
      this.showNotNeededDropdowns = true;
    } else {
      this.acordForm.get('review').setValue('');
      this.acordForm.get('review').enable();
      this.showNotNeededDropdowns = false;
    }
  }

  // Rsm Acord Dropdown attachments selection
  setRsmAcord(event: any) {
    this.isDirty = true
    if (event.id == "Acord") {
      this.selectedRsmAcord = event.value
    }
    if (event.id == "Loss Runs") {
      this.selectedRsmLossRuns = event.value
    }
    if (event.id == "Application") {
      this.selectedRsmApplication = event.value
    }
    if (event.id == "SOV") {
      this.selectedRsmSOV = event.value
    }
    if (event.id == "Financials") {
      this.selectedRsmFinancials = event.value
    }
    if (event.id == "MOD Document") {
      this.selectedRsmModDocument = event.value
    }
    if (event.id == "BOR") {
      this.selectedBOR = event.value
    }
  }

  createAccordReview() {
    if (!this.acordForm.get('review').value) {
      this.showWarningBox3();
    } else if (this.acordForm.get('review').value == 'Requested' && !this.wbaDetails.contactId) {
      this.showWarningBox6();
    }
    else {
      let data = {
        wbaId: this.activeWbaId,
        aeRequest: "No",
        producerRequest: "Yes",
        acordStatus: this.acordForm.get('review').value,
        userId: this.loggedInUserId,
        isBorDefined: false
      };
      this.wbaService.createAccordReview(data).subscribe(res => {
        this.isDirty = false;
        this.sdRsmStatus = '';
        this.selectedReviewResponse = res;
        this.setCreatedAcordId.emit(res);
        if (res) {
          if (res?.id === 0) {
            this.showWarningBox4();
          } else {
            this.getAccessReviewList();
            this.getWBAFileById();
            if (this.acordForm.get('bor').value == 'No') this.borResponse = false;
            // this._notifierService._acordSubmitted.next(true);
            // this._dataCacheService.__acordSubmitted.next(true);
            let data = {
              value: 'accord',
              wbaId: this.activeWbaId
            }
            this._dataCacheService.emitrefreshaccord(data);
            this.getWhiteBoardDetails(this.activeWbaId);
          }
        }
      }, (err: any) => {
        this.errorList = []
        if (err.status == 403) {
          // return
        }
        if (err.status == 400) {
          let obj = err.error.errors;
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
          this.errorList = [];
          this.showError = true;
          let errorMessage = 'Access denied, your permissions do not allow you to proceed.'
          this.errorList.push(errorMessage);
          //this.showWarningBox7(errorMessage);
        }
        if (err.status == 500) {
          let errorMessage = "Error : " + err.message + ", Status: " + err.status;;
          this.errorList.push(errorMessage);
        }
      });
    }
  }

  showWarningBox() {
    let message = `Audit Wizard is not completed.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox2() {
    let message = `Please complete the BOR Plan of Attack Form.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox3() {
    let message = `Please select review status.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox4() {
    let message = this.selectedReviewResponse?.reason
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox5() {
    let message = this.sdrsmResponse?.reason
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox6() {
    let message = "Please select contact."
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox7(msg: any) {
    let message = msg
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }

  warningBoxCancel() {
    this._warningBox.isVisible = false;
  }

  getAccessReviewList() {
    let requestBody = {
      wbaId: this.activeWbaId,
      isRenewal: this.wbaDetails?.transType == 'NB' ? false : true
    };

    this.wbaService.getAccessReviewList(requestBody).subscribe(res => {
      console.log("getAccessReviewList", res);
      this._notifierService._wbaAccordStatus.next(res.acordResponse)
      this.AcordResponse = res?.acordResponse;
      this.wbaResponse = res?.wbaReviews;
      this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', res);
      this._dataCacheService.compressAndCacheData('wba_AcordResponse', this.AcordResponse);
      this._dataCacheService.compressAndCacheData('wba_wbaResponse', this.wbaResponse);
      this.marketingReviewArray = this.AcordResponse?.find(e => e?.requestType == 'Marketing Review');
      this.marketingReviewStatus = this.marketingReviewArray?.requestStatus
      this.marketingCreatedByUserName = this.marketingReviewArray?.createdByUserName;
      this.AcordArray = this.AcordResponse?.find(a => a?.requestType == "Acord Review Status")
      this.acordStatus = this.AcordArray?.requestStatus
      if (this.AcordResponse?.find(a => a?.requestType == "SD Approval")) {
        this.rsmArray = this.AcordResponse?.find(a => a?.requestType == "SD Approval")
        this.sdRsmStatus = this.rsmArray?.requestStatus
        this.rsmCreatedByUserName = this.rsmArray?.createdByUserName;
      }
      this.setSDrsmFields();
      this.setVisibleFields();
      this.setFieldsOnNotNeeded();
      if (this.sdRsmStatus == "Pending" || this.sdRsmStatus == "Approved") {
        this.handleAcordIconStatus.emit({ label: 'CE Status', value: false });
        this.SDRSMapproval = false;
      } else {
        this.handleAcordIconStatus.emit({ label: 'CE Status', value: true });
      }
      this.setwbaReviewTable();
    });
    this.acordWBA = true;
    this.marketingReview = false;
  }

  setSDrsmFields() {
    this.SDRSMapproval = (this.marketingReviewStatus == "Completed" || this.sdRsmStatus == "Denied") ? true : false;
  }

  setVisibleFields() {
    // negetive cases
    this.hideReviewDropdown = ((this.acordStatus === 'Requested' || this.acordStatus === 'Completed')
      && (this.marketingReviewStatus == "Pending" || this.marketingReviewStatus == "Completed")) ? false : true;
  }

  setFieldsOnNotNeeded() {
    if (this.acordStatus == "Not Needed" && (this.sdRsmStatus == "Pending" || this.sdRsmStatus == "Denied" || this.sdRsmStatus == "Approved")) {
      this.hideReviewDropdown = false;
    }
  }

  setwbaReviewTable() {
    if (this.wbaResponse[0]?.wbaReviewStatus != null) {
      this.wbaReviewTable = true;
      this.SDRSMapproval = false;
      if (this.wbaResponse[0]?.wbaReviewStatus == "Complete") {
        this.hideReviewDropdown = false;
      }
      else if (this.wbaResponse[0]?.wbaReviewStatus == "Incomplete") {
        this.hideReviewDropdown = true;
      }
    } else {
      this.wbaReviewTable = false;
    }
  }

  // acord review for SD/RSM Approval
  getWBAFileById() {
    let requestBody = {
      wbaId: this.activeWbaId,
      lineId: this.BusinessLineId
    }
    this.wbaService.getWBAFileById(requestBody).subscribe(res => {
      this.wbaFileResponse = res.wbaFiles;
      this._dataCacheService.compressAndCacheData('wba_wbaFileResponse', this.wbaFileResponse);
      this.notBOR = this.wbaFileResponse.filter((res: any) => res.fileType !== "BOR");

      this.notBOR?.forEach((res: any) => {
        this.sdrsmDropdowns?.push(this.formbuilder.group({
          fileId: [res.fileId],
          fileStatus: [res.fileStatus],
          fileType: [res.fileType],
          options: [res.options],
          selectedOption: ['', [Validators.required]]
        }))
      });

      this.notBOR?.forEach((res: any) => {
        this.notNeededDropdowns?.push(this.formbuilder.group({
          fileId: [res.fileId],
          fileStatus: [res.fileStatus],
          fileType: [res.fileType],
          options: [res.options],
          selectedOption: ['', [Validators.required]]
        }))
      });

      let borFormGroup = this.sdrsmDropdowns?.controls?.find((res: any) => res.value?.fileType == 'BOR');
      if (borFormGroup?.value?.fileType == 'BOR') {
        borFormGroup.patchValue({
          fileId: 7,
          fileStatus: null,
          fileType: 'BOR',
          options: 'Yes,No',
          selectedOption: 'No'
        });
      }
      let borFormGroupForFinancials = this.sdrsmDropdowns?.controls?.find((res: any) => res.value?.fileType == 'Financials');
      if (borFormGroupForFinancials?.value?.fileType == 'Financials') {
        borFormGroupForFinancials.patchValue({
          fileId: 5,
          fileStatus: null,
          fileType: 'Financials',
          options: 'Attached,Not Needed',
          selectedOption: 'Not Needed'
        });
      }
      let borFormGroupForFinancials2 = this.notNeededDropdowns?.controls?.find((res: any) => res.value?.fileType == 'Financials');
      if (borFormGroupForFinancials2?.value?.fileType == 'Financials') {
        borFormGroupForFinancials2.patchValue({
          fileId: 5,
          fileStatus: null,
          fileType: 'Financials',
          options: 'Attached,Not Needed',
          selectedOption: 'Not Needed'
        });
      }
    })
    this._loaderService.hide();
  }

  updateAccessReview() {
    let requestBody = {
      id: this.selectedReviewResponse,
      wbaId: this.activeWbaId,
      acordStatus: this.acordStatus,
      marketingStatus: this.marketingReviewStatus,
      marketingUser: null,
      marketingUserId: null,
      marketingComments: "",
      rsmStatus: this.selectedRsmApproval,
      rsmUser: "",
      rsmUserId: "",
      rsmComments: "",
      aeUserId: "",
      amUserId: "",
      amUser: "",
      amStatus: "",
      amComments: ""
    }
    this.wbaService.updateRSMApproval(requestBody).subscribe(res => {
      if (res) {
        this.getWhiteBoardDetails(this.activeWbaId);
        this.getAccessReviewList();
        this.getWBAFileById();
        this.sdrsmResponse = res
        if (res?.id === 0) {
          this.showWarningBox5();
        }
      }
    }, (err: any) => {
      this.errorList = []
      if (err.status == 403) {
        // return
      }
      if (err.status == 400) {
        let obj = err.error.errors;
        var arr = Object.keys(obj).map((key) => ({
          type: key,
          value: obj[key],
        }));
        this.errorList = [];
        this.showError = true;
        let errorMessage = 'Access denied, your permissions do not allow you to proceed.'
        this.errorList.push(errorMessage);
        //this.showWarningBox7(errorMessage);
      }
      if (err.status == 500) {
        let errorMessage = "Error : " + err.message + ", Status: " + err.status;;
        this.errorList.push(errorMessage);
      }
    });
  }

  closeError() {
    this.showError = false;
  }


  createWBAFileReview(callFrom: string) {
    if (!this.acordForm.valid && callFrom == 'Review') {
      this.submitted = true;
      return;
    } else if (!this.sdrsmForm.valid && callFrom == 'SD Approval') {
      this.submitted2 = true;
      return;
    } else if (this.acordForm.get('review').value == 'Not Needed' && !this.wbaDetails.contactId && callFrom == 'Review') {
      this.showWarningBox6();
    } else {
      if (!this.borResponse && (this.acordForm.get('bor').value === "Yes" || this.selectedBOR == 'Yes')) {
        this.showWarningBox2();
      }
      else {
        if (this.acordForm.get('review').value == 'Not Needed' && callFrom == 'Review') {
          let data = {
            wbaId: this.activeWbaId,
            aeRequest: "No",
            producerRequest: "Yes",
            acordStatus: this.acordForm.get('review').value,
            userId: this.loggedInUserId,
            isBorDefined: this.acordForm.get('bor').value == 'No' ? false : true
          };
          this.wbaService.createAccordReview(data).subscribe(res => {
            this.sdRsmStatus = '';
            this.selectedReviewResponse = res;
            this.setCreatedAcordId.emit(res);
            if (res) {
              this.getWhiteBoardDetails(this.activeWbaId);
              this.getAccessReviewList();
              this.getWBAFileById();
              if (this.acordForm.get('bor').value == 'No') this.borResponse = false;
            }
          });
        }
        let requestBody =
        {
          wbaFileReviews: [
            {
              wbaId: this.activeWbaId,
              fileId: 1,
              reviewStatus: this.selectedRsmAcord
            },
            {
              wbaId: this.activeWbaId,
              fileId: 2,
              reviewStatus: this.selectedRsmApplication
            },
            {
              wbaId: this.activeWbaId,
              fileId: 3,
              reviewStatus: this.selectedRsmModDocument
            },
            {
              wbaId: this.activeWbaId,
              fileId: 4,
              reviewStatus: this.selectedRsmLossRuns
            },
            {
              wbaId: this.activeWbaId,
              fileId: 5,
              reviewStatus: this.selectedRsmSOV
            },
            {
              wbaId: this.activeWbaId,
              fileId: 6,
              reviewStatus: this.selectedRsmFinancials
            },
            {
              wbaId: this.activeWbaId,
              fileId: 7,
              reviewStatus: this.acordForm.get('bor').value
            }

          ]
        }

        this.wbaService.createWBAFileReview(requestBody).subscribe(res => {
          if (res) {
            this.isDirty = false;
            if (requestBody.wbaFileReviews[6].reviewStatus == "Yes")
              this._notifierService._isBusinessStatusChange.next('Existing Client - Broker of Record');
            // if (res) this._notifierService._acordSubmitted.next(true);
            // this._dataCacheService.__acordSubmitted.next('acordSubmitted');
            let data = {
              value: 'accord',
              wbaId: this.activeWbaId
            }
            this._dataCacheService.emitrefreshaccord(data);
            if (callFrom == 'SD Approval') {
              this.updateAccessReview()
            }
          }
        })
      }
    }
  }

  getWhiteBoardDetails(wbaId: any) {
    this._addWBAService.getWBAById(wbaId).subscribe(resp => {
      if (resp) {
        console.log("accord", resp)
        this.wbaDetails = resp.whiteBoardActivityInfo;
        this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
        this.wbaProgressChange = resp.whiteBoardActivityInfo.progressName;
        this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
        this._notifierService._isAccordRequested.next(resp.whiteBoardActivityInfo.borInDiscussionEnable);
        this.setUpdatedProgressId.emit(resp.whiteBoardActivityInfo.progressId);
        this.prospectId = resp.whiteBoardActivityInfo.prospectId
        this.epicUniquePolicyId = resp.whiteBoardActivityInfo?.epicUniquePolicyId;
        this.wbaTransType = resp.whiteBoardActivityInfo?.transType;
        if (this.acordForm) this.setDefaultBorValue();
      }
    });
  }

  setDefaultBorValue() {
    if (this.wbaDetails.borInDiscussion) {
      this.acordForm.get('bor').setValue('Yes');
      this.acordForm.get('bor').disable();
    } else if (this.wbaDetails.isBorDefined == 'Yes') {
      this.acordForm.get('bor').setValue('Yes');
      this.acordForm.get('bor').enable();
    } else {
      this.acordForm.get('bor').setValue('No');
      this.acordForm.get('bor').enable();
    }

    if (this.acordForm.get('bor').value == 'Yes' && this.hideReviewDropdown) {
      this.acordForm.get('review').setValue('Not Needed');
      this.acordForm.get('review').disable();
      this.showNotNeededDropdowns = true;
      this.hideReviewDropdown = true;
    }
  }

  getWbaBorDetailList() {
    let requestBody = {
      "wbaId": this.activeWbaId
    }
    this.wbaService.getWbaBorDetailList(requestBody).subscribe(res => {
      this.borResponse = res.wbaBorDetailList;
    });
  }

  openeEmailTemplateModal() {
    const modalRef = this.modalService.open(SubmissionTemplateComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });

    let data = {
      wbaId: this.activeWbaId,
      prospectId: this.prospectId,
      epicUniquePolicyId: this.epicUniquePolicyId,
      wbaTransType: this.wbaTransType,
      priorPolicyId: this.wbaDetails.priorPolicyId
    }
    modalRef.componentInstance.wbaIdData = data
  }

  openBorModal() {
    this.openBor.emit(true);
  }

  saveWBA(action: string) {
    if (action === 'next') {
      this.sdRsmStatus == "Pending" ? this.nextTab.emit(this.sdrsmTab) : this.nextTab.emit(this.tabName)
    }
    else this._router.navigate([this._navigationPath.getProspectsWhiteboardsUrl()]);
  }

  mapWbaFileById() {
    this.notBOR = this.wbaFileResponse.filter((res: any) => res.fileType !== "BOR");
    this.notBOR.forEach((res: any) => {
      this.sdrsmDropdowns.push(this.formbuilder.group({
        fileId: [res.fileId],
        fileStatus: [res.fileStatus],
        fileType: [res.fileType],
        options: [res.options],
        selectedOption: ['', [Validators.required]]
      }))
    });

    this.notBOR.forEach((res: any) => {
      this.notNeededDropdowns.push(this.formbuilder.group({
        fileId: [res.fileId],
        fileStatus: [res.fileStatus],
        fileType: [res.fileType],
        options: [res.options],
        selectedOption: ['', [Validators.required]]
      }))
    });

    let borFormGroup = this.sdrsmDropdowns?.controls.find((res: any) => res.value?.fileType == 'BOR');
    if (borFormGroup?.value?.fileType == 'BOR') {
      borFormGroup.patchValue({
        fileId: 7,
        fileStatus: null,
        fileType: 'BOR',
        options: 'Yes,No',
        selectedOption: 'No'
      });
    }
    let borFormGroupForFinancials = this.sdrsmDropdowns?.controls?.find((res: any) => res.value?.fileType == 'Financials');
    if (borFormGroupForFinancials?.value?.fileType == 'Financials') {
      borFormGroupForFinancials.patchValue({
        fileId: 5,
        fileStatus: null,
        fileType: 'Financials',
        options: 'Attached,Not Needed',
        selectedOption: 'Not Needed'
      });
    }
    let borFormGroupForFinancials2 = this.notNeededDropdowns?.controls?.find((res: any) => res.value?.fileType == 'Financials');
    if (borFormGroupForFinancials2?.value?.fileType == 'Financials') {
      borFormGroupForFinancials2.patchValue({
        fileId: 5,
        fileStatus: null,
        fileType: 'Financials',
        options: 'Attached,Not Needed',
        selectedOption: 'Not Needed'
      });
    }
  }
}
